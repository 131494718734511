import { Inject, Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES, InterruptSource, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';
import { APP_CONFIG_TOKEN, AppConfigModel } from '@quipex/shared/data';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionIdleService {
  sessionIdled$: Subject<unknown> = new Subject<unknown>();
  sessionActivated$: Subject<unknown> = new Subject<unknown>();
  sessionTimingOut$: Subject<number> = new Subject<number>();
  sessionTimedOut$: Subject<unknown> = new Subject<unknown>();

  constructor(
    @Inject(APP_CONFIG_TOKEN) private appConfig: AppConfigModel,
    private idle: Idle
  ) {
    // set idle parameters
    idle.setIdle(this.appConfig.sessionConfig.inactiveTimeoutSeconds); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(this.appConfig.sessionConfig.idleTimeoutSeconds); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(this.createCustomInterruptSources(null)); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe((value) => {
      this.sessionIdled$.next(value);
    });

    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe((value) => {
      this.sessionActivated$.next(value);
      // reset the idle process
      idle.watch();
    });

    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe((seconds) =>
      this.sessionTimingOut$.next(seconds)
    );

    // do something when the user has timed out
    idle.onTimeout.subscribe((value) => this.sessionTimedOut$.next(value));
  }

  setIdleInterrupts(sources?: InterruptSource[]) {
    this.idle.setInterrupts(sources ?? DEFAULT_INTERRUPTSOURCES);
  }

  clearIdleInterrupts() {
    this.idle.clearInterrupts();
  }

  startIdleProcess() {
    this.idle.watch();
  }

  stopIdleProcess() {
    this.idle.stop();
  }

  createCustomInterruptSources(options: any) {
    return [
        new DocumentInterruptSource('keydown mousedown touchstart touchmove scroll', options),
        new StorageInterruptSource(options)
    ];
  }
}
