import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserStore } from '@quipex/shared/data';
import { NavbarComponent } from '../navbar/navbar.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'qpx-header',
    imports: [CommonModule, RouterModule, NavbarComponent],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    private readonly userStore = inject(UserStore);

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$);

    protected readonly logoUrl = computed(() => {
        const me = this.me() as any;
        return (me?.['logo'] as string) ?? '';
    });
}
