import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BuildingSearchComponent, NotificationComponent } from '@quipex/shared/components';
import { HasPermissionDirective } from '@quipex/shared/directives';

@Component({
    selector: 'qpx-navbar',
    standalone: true,
    imports: [
        CommonModule,
        BuildingSearchComponent,
        NotificationComponent,
        HasPermissionDirective
    ],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent { }