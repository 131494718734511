import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { UserStore } from '@quipex/shared/data';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { filter } from 'rxjs';

@Component({
    selector: 'qpx-layout',
    imports: [RouterModule, SidebarComponent, HeaderComponent],
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
    private readonly destroyRef = inject(DestroyRef);
    private readonly userStore = inject(UserStore);

    protected readonly isLoading = signal(true);
    protected readonly menuExpanded = signal(false);

    constructor() {
        this.userStore
            .select('me')
            .pipe(
                filter((user) => !!user?.role),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.isLoading.set(false);
                },
            });
    }

    protected onToggleChange(expanded: boolean): void {
        this.menuExpanded.set(expanded);
    }
}
