import { Injectable, inject } from "@angular/core";
import { UserStore } from "@quipex/shared/data";

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    private userStore = inject(UserStore);

    load(): void {
        this.userStore.dispatch();
    }
}