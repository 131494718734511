import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnDestroy, OnInit, inject, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AuthService, NavigationHelperService, User, UserStore } from '@quipex/shared/data';
import { HasPermissionDirective, HasRoleDirective } from '@quipex/shared/directives';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'qpx-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        HasPermissionDirective,
        HasRoleDirective
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    toggleChange = output<boolean>();

    userInitials!: string;
    isExpanded = false;
    hovered = false;
    isProdEnvironment!: boolean;

    private destroyRef = inject(DestroyRef);
    private authService = inject(AuthService);
    private userStore = inject(UserStore);
    private navigationHelperService = inject(NavigationHelperService);

    ngOnInit(): void {
        this.isProdEnvironment = environment.isProd;
        this.userStore.select('me')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user: User) => {
                this.userInitials = this.getUserInitials(user);
            });
    }

    navigateToDashboard(): void {
        this.navigationHelperService.navigateToDashboard();
    }

    onExpandIconClick(): void {
        this.isExpanded = !this.isExpanded;
        this.hovered = false;
        this.toggleChange.emit(this.isExpanded);
    }

    onMouseEnter(): void {
        setTimeout(() => { this.hovered = true; }, 100);
    }

    onMouseLeave(): void {
        setTimeout(() => { this.hovered = false }, 100);
    }

    signout(): void {
        this.authService.logout();
    }

    private getUserInitials(user: User): string {
        let initials = '';
        const splitArr = user.name?.split(' ');
        if (!splitArr) {
            return initials;
        }
        if (splitArr.length) {
            initials = splitArr[0].substring(0, 1);
        }
        if (splitArr.length > 1) {
            initials = `${initials}${splitArr[1].substring(0, 1)}`;
        }
        return initials;
    }

}