import { DatePipe } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    inject,
    provideAppInitializer,
} from '@angular/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    RouterModule,
    provideRouter,
    withComponentInputBinding,
    withPreloading,
    withRouterConfig,
} from '@angular/router';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalService,
} from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { provideNgIdle } from '@ng-idle/core';
import { provideTranslateService } from '@ngx-translate/core';
import {
    APP_CONFIG_TOKEN,
    GlobalErrorHandler,
    MSALGuardConfigFactory,
    MSALInstanceFactory,
    MSALInterceptorConfigFactory,
    ManageHttpInterceptor,
    MonitoringService,
} from '@quipex/shared/data';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { ConfigService } from './services/config.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function configFactory(config: ConfigService) {
    return () => config.load();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            [
                ...appRoutes,
                {
                    path: '',
                    loadComponent: () =>
                        import('@azure/msal-angular').then(
                            (mod) => mod.MsalRedirectComponent
                        ),
                },
            ],
            withPreloading(PreloadAllModules),
            withComponentInputBinding(),
            withRouterConfig({ paramsInheritanceStrategy: 'always' })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(
            BrowserAnimationsModule,
            MsalModule,
            RouterModule.forRoot(appRoutes, {
                // Don't perform initial navigation in iframes or popups
                initialNavigation:
                    !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
                        ? 'enabledNonBlocking'
                        : 'disabled', // Set to enabledBlocking to use Angular Universal
            }),
            MatSnackBarModule,
            MatNativeDateModule
        ),
        provideTranslateService(),
        provideNgIdle(),
        DatePipe,
        { provide: APP_CONFIG_TOKEN, useValue: environment },
        provideAppInitializer(() => {
            const initializerFn = configFactory(inject(ConfigService));
            return initializerFn();
        }),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ManageHttpInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [APP_CONFIG_TOKEN],
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [APP_CONFIG_TOKEN],
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MonitoringService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
};
