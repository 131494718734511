import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    output,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
    AuthService,
    NavigationHelperService,
    User,
    UserStore,
} from '@quipex/shared/data';
import {
    HasPermissionDirective,
    HasRoleDirective,
} from '@quipex/shared/directives';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'qpx-sidebar',
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        HasPermissionDirective,
        HasRoleDirective,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    private readonly authService = inject(AuthService);
    private readonly userStore = inject(UserStore);
    private readonly navigationHelperService = inject(NavigationHelperService);

    public readonly toggleChange = output<boolean>();

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$);
    protected readonly userInitials = computed(() =>
        this.getUserInitials(this.me())
    );
    protected readonly isExpanded = signal(false);
    protected readonly hovered = signal(false);
    protected readonly isProd = environment.isProd;
    protected readonly isDemo = environment.isDemo;

    // Regular method to determine if insights menu should be shown
    protected showInsightsMenu(): boolean {
        // Hide in production
        if (this.isProd) {
            return false;
        }

        // In demo, only show for authorized users
        if (this.isDemo) {
            const account = this.authService.getAccount();
            const authorizedEmails =
                environment.authorizedUsers?.insights || [];
            return !!account && authorizedEmails.includes(account.username);
        }

        // Show in all other environments
        return true;
    }

    protected navigateToDashboard(): void {
        this.navigationHelperService.navigateToDashboard();
    }

    protected onExpandIconClick(): void {
        this.isExpanded.update((value) => !value);
        this.hovered.set(false);
        this.toggleChange.emit(this.isExpanded());
    }

    protected onMouseEnter(): void {
        this.hovered.set(true);
    }

    protected onMouseLeave(): void {
        this.hovered.set(false);
    }

    protected signout(): void {
        this.authService.logout();
    }

    private getUserInitials(user?: User | null): string {
        let initials = '';

        if (!user) return initials;

        const splitArr = user.name?.split(' ');
        if (!splitArr) {
            return initials;
        }
        if (splitArr.length) {
            initials = splitArr[0].substring(0, 1);
        }
        if (splitArr.length > 1) {
            initials = `${initials}${splitArr[1].substring(0, 1)}`;
        }
        return initials;
    }
}
