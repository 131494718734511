import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserStore } from '@quipex/shared/data';
import { Subject, takeUntil } from 'rxjs';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
    selector: 'qpx-header',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        NavbarComponent
    ],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    logoUrl!: string;

    private destroy$ = new Subject<void>();
    private userStore = inject(UserStore);

    ngOnInit(): void {
        this.userStore.select('me')
            .pipe(takeUntil(this.destroy$))
            .subscribe((user: any) => {
                this.logoUrl = user?.logo;
            })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}