@if(!isLoading()) {
    <div class="layout">
        <qpx-sidebar (toggleChange)="onToggleChange($event)"></qpx-sidebar>
    
        <div class="header"
             [ngClass]="{'expanded': menuExpanded()}">
            <qpx-header></qpx-header>
        </div>
    
        <div class="content"
             [ngClass]="{'expanded': menuExpanded()}">
            <div class="router-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
}