import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BuildingSearchComponent, NotificationComponent } from '@quipex/shared/components';

@Component({
    selector: 'qpx-navbar',
    standalone: true,
    imports: [
        CommonModule,
        BuildingSearchComponent,
        NotificationComponent
    ],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent { }