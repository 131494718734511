<div class="header">
    <div class="header-content">
        <a routerLink="/dashboard" class="cursor-pointer">
            <img
                alt="Logo"
                src="./assets/media/quipex/quipex-text.png"
                class="header-logo"
            />
        </a>

        <div class="header-content__right">
            @if (logoUrl()) {
                <div class="logo-wrapper">
                    <img class="logo" [src]="logoUrl()" alt="logo" />
                </div>
            }
            <qpx-navbar />
        </div>
    </div>
</div>
