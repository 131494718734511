@if (!isLoading()) {
    <div class="layout">
        <qpx-sidebar (toggleChange)="onToggleChange($event)" />

        <div class="header" [class.expanded]="menuExpanded()">
            <qpx-header />
        </div>

        <div class="content" [class.expanded]="menuExpanded()">
            <router-outlet />
        </div>
    </div>
}
