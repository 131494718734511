<div class="sidebar"
     [ngClass]="{ expanded: isExpanded || (!isExpanded && hovered) }"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()">
    <div class="sidebar__inner">
        <div class="sidebar-logo">
            <div class="toggle"
                 (click)="onExpandIconClick()">
                <mat-icon color="primary">{{
                    isExpanded ? 'chevron_right' : 'chevron_right'
                    }}</mat-icon>
            </div>
        </div>

        <div class="menu-items">
            <!-- begin::Dashboard -->
             <ng-container *hasRole="['!Owner General', '!Unit Owner Read']">
                 <div *hasRole="['*Owner']"
                      class="menu-item">
                     <a class="menu-link"
                        data-testid="dashboardLink"
                        routerLink="/dashboard"
                        routerLinkActive="active"
                        (click)="navigateToDashboard()">
                         <mat-icon class="material-symbols-outlined">dashboard</mat-icon>
                         <span class="menu-label">Dashboard</span>
                     </a>
                 </div>
                 <!-- end::Dashboard -->
             </ng-container>

            <!-- begin::Insights -->
             <ng-container *hasPermission="['update_buildings']">
                 <div class="menu-item">
                     <a class="menu-link"
                        data-testid="dashboardLink"
                        routerLink="/insights"
                        routerLinkActive="active">
                         <mat-icon class="material-symbols-outlined">search</mat-icon>
                         <span class="menu-label">Insights</span>
                     </a>
                 </div>
             </ng-container>
            <!-- end::Insights -->

            <!-- begin::Portfolio -->
            <div class="menu-item header">
                <div class="menu-header"
                     [ngClass]="{ active: isExpanded || (isExpanded && hovered) }">
                    <span class="menu-label">Portfolio</span>
                </div>
            </div>
            <!-- end::Portfolio -->

            <!-- begin::Buildings -->
            <div *hasPermission="['read_buildings']"
                 class="menu-item">
                <a class="menu-link"
                   routerLink="/buildings"
                   data-testid="buildingLink"
                   routerLinkActive="active">
                    <mat-icon class="material-symbols-outlined"> home_storage </mat-icon>
                    <span class="menu-label">Buildings</span>
                </a>
            </div>
            <!-- end::Buildings -->

            <!-- begin::Tasks -->
            <ng-container *hasPermission="['read_task']">
                <div class="menu-item">
                    <a class="menu-link"
                        routerLink="/tasks"
                        data-testid="tasksLink"
                        routerLinkActive="active">
                        <mat-icon class="material-symbols-outlined"> task </mat-icon>
                        <span class="menu-label">Tasks</span>
                    </a>
                </div>
            </ng-container>
            <!-- end::Tasks -->

            <!-- begin::Keys -->
            <ng-container *hasPermission="['read_buildings']">
                <div class="menu-item">
                    <a class="menu-link"
                        routerLink="/keys"
                        data-testid="keysLink"
                        routerLinkActive="active">
                        <mat-icon class="material-symbols-outlined"> key </mat-icon>
                        <span class="menu-label">Keys</span>
                    </a>    
                </div>
            </ng-container>
            <!-- end::Keys -->

            <!-- begin::Admin -->
            <ng-container *hasRole="['*Admin']">
                <div class="menu-item header">
                    <div class="menu-header"
                         [ngClass]="{ active: isExpanded || (isExpanded && hovered) }">
                        <span class="menu-label">Admin</span>
                    </div>
                </div>

                <!-- begin::Admin Manaage -->
                <div class="menu-item">
                    <a class="menu-link"
                       data-testid="manageLink"
                       routerLink="/admin"
                       routerLinkActive="active">
                        <mat-icon class="material-symbols-outlined"> group </mat-icon>
                        <span class="menu-label">Manage</span>
                    </a>
                </div>
                <!-- end::Admin Manaage -->
            </ng-container>
            <!-- end::Admin -->

        </div>

        <div class="profile">
            <div class="menu-item">
                <a class="menu-link"
                   data-testid="profileLink"
                   routerLink="/profile"
                   routerLinkActive="active">
                    <div class="initials">{{userInitials}}</div>
                    <span class="menu-label">Profile</span>
                </a>
            </div>
        </div>

        <div class="sign-out">
            <button mat-flat-button
                    data-testid="signOutLink"
                    color="secondary"
                    (click)="signout()">
                <mat-icon>logout</mat-icon>
                <span class="sign-out__label">Sign Out</span>
            </button>
        </div>
    </div>
</div>